import Icon from '__designkit__/icon/Icon';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useUserInfo } from 'query/user/useUserInfoQuery';
import { V2BackgroundColors, V2BackgroundColorsType } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import IndicatorLottie from '__designkit__/assets/lottie/tutorial_indicate_lottie.json';
import AlarmLottie from '__designkit__/assets/lottie/alarms.json';
import Lottie from 'lottie-react';
import colors from '__designkit__/common/colors';
import MainTutorialIndicatorAlarm from './MainTutorialIndicatorAlarm';

const Frame = styled.div`
  position: fixed;
  top:0;
  left:0;
    width: 100%; /* 전체 화면 */
  height: 100%; /* 전체 화면 */
  z-index: 100006;
  .bell-area {
    position: absolute;
    top: 8px;
    right: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: ${colors.WHITE_100};
    z-index: 100006;

    .lottie-indicator {
      position: absolute;
      top: 22px;
      left: 50%; 
      transform: translate(-50%, -50%); 
      width: 50px;
      height: 50px;
      z-index: 10006;
    }
  }
`;

const dimOpacityAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.6;
  }
`;

const Dim = styled.div<{backgroundColor:V2BackgroundColorsType}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => V2BackgroundColors[props.backgroundColor]};
  opacity: 0.6;
  animation: ${dimOpacityAnimation} 0.3s ease-out;
  animation-duration: 0.4s;
  z-index: 10005;
`;

interface IAlarmTutorialMain {
  onClose: () => void;
  setStep : React.Dispatch<React.SetStateAction<number>>;

}
const MainTutorialAlarm:FC<IAlarmTutorialMain> = ({ setStep, onClose }) => {
  const { data: userInfoData } = useUserInfo();
  const contentsRef = useRef<HTMLDivElement>(null);

  const [backgroundColor, setBackgroundColor] = useState<V2BackgroundColorsType>(V2BackgroundColorsType.transparent);
  const [isIndicatorVisible, setIsIndicatorVisible] = useState<boolean>(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setBackgroundColor(V2BackgroundColorsType.dimmer); // 배경색을 회색으로 설정
      setIsIndicatorVisible(true);
    }, 500);

    return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머를 정리
  }, []);
  const handleNextClick = () => {
    onClose();
  };
  // 스크롤 방지 핸들러
  const preventScroll = useCallback((e: TouchEvent | WheelEvent) => {
    if (contentsRef.current && e.target instanceof HTMLElement && contentsRef.current.contains(e.target)) {
      e.preventDefault();
    }
  }, []);

  useEffect(() => {
    // Wheel 및 Touch 이벤트 리스너 추가
    window.addEventListener('wheel', preventScroll, { passive: false });
    window.addEventListener('touchmove', preventScroll, { passive: false });

    return () => {
      // 이벤트 리스너 제거
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [preventScroll]);

  return (
    <Frame ref={contentsRef}>
      {/* 모달 형식으로 변경~ */}
      <Dim backgroundColor={backgroundColor} />

      <div className='bell-area'>
        <Icon name='bell' size={32} />
        <Lottie animationData={IndicatorLottie} className='lottie-indicator' />
      </div>
      <MainTutorialIndicatorAlarm
        titleText='카카오톡 및 잡다 알림 확인하기'
        descText={`
          ${userInfoData?.name}님에게 딱 맞는 매칭 제안이 도착했어요!\n카카오톡 알림과 잡다 알림을 확인해 볼까요? \n(카카오톡 알림은 최초 한 번만 발송됩니다.)
        `}
        stepText='01'
        buttonText='다음'
        position='bottom'
        onClickNext={handleNextClick}
        visible={isIndicatorVisible}
      />
    </Frame>
  );
};

export default MainTutorialAlarm;
