import CodeAPI from 'api/codeAPI';
import { IJDToastProps } from 'components/_v2/_common/toast/JDToast';
import { CenterHomeType, EmploymentHomeType } from 'consts/_v2/CenterType';
import { IJobGroupsWithTitle } from 'interfaces/_v2/ICodeRqRs';
import { INameCodeRs } from 'interfaces/rqrs/ICommonRqRs';
import { action, observable } from 'mobx';

type ContextHistory = { scroll: number, hideHeader: boolean };

interface IContextHistory {
  [key: string]: ContextHistory;
}

interface IBlendMode {
  activeHandler: boolean;
  active: boolean;
  activeForce?: boolean;
  color?: string;
  iconFill?: string;
}

export default class Context {
  @observable initialized = false;
  @observable contextHistory:IContextHistory = {};
  @observable loading:number = 0;
  @observable loginRedirectPath: string = '/';
  @observable hideHeader = false;
  @observable preventScroll = false;
  @observable blendMode: IBlendMode = { activeHandler: false, active: false };
  @observable error500 = false;
  @observable previousPathname: string = '';
  @observable previousPathList: string[] = [];
  @observable otherPageRedirectUrl: string = '';
  @observable employmentHomeType:EmploymentHomeType = EmploymentHomeType.CALENDAR;
  @observable centerHomeType:CenterHomeType = CenterHomeType.ACCA;
  @observable autoLogin:boolean = false;
  @observable toastObject: IJDToastProps = { isOpen: false, type: 'INFO', message: '', subMessage: '', duration: 2000, position: 'bottom', closeable: false, justSubMessage: false, bottom: undefined, onDidDismiss: undefined };
  @observable keyBoardHeight: number = 0;
  @observable iPhoneNotch:boolean = false;
  @observable hideBottomNav:boolean = false;
  @observable jobGroupsWithTitles: IJobGroupsWithTitle[] = [];
  @observable jobTitles: { [jobRtoupCode: string]: { name: string, titles: INameCodeRs[] } } = {};
  @observable isGlobalModalOpen = false;
  @observable isPreferredClicked = false;

  @action
  init = async () => {
    this.initialized = true;

    const res = await CodeAPI.getJobGroupsJobTitles();
    const temp: { [jobRtoupCode: string]: { name: string, titles: INameCodeRs[] } } = {};
    res.forEach((jobGroup) => {
      temp[jobGroup.code] = {
        name: jobGroup.name,
        titles: jobGroup.jobTitles,
      };
    });
    this.setJobGroupsWithTitles(res);
    this.setJobTitles(temp);
  }

  @action
  setRedirectUrl = (url: string) => {
    this.loginRedirectPath = url;
  }

  @action
  setToastObject = (toastObject: IJDToastProps) => {
    this.toastObject = toastObject;
  }

  @action
  setKeyBoardHeight = (value: number) => {
    this.keyBoardHeight = value;
  };

  updatePreviousPath = (path: string) => {
    this.previousPathList.push(path);
    if (this.previousPathList.length > 3) {
      this.previousPathList.shift();
    }
  }

  @action
  setJobGroupsWithTitles = (jobGroupsWithTitles: IJobGroupsWithTitle[]) => {
    this.jobGroupsWithTitles = jobGroupsWithTitles;
  }

  @action
  setJobTitles = (jobTitles: { [jobRtoupCode: string]: { name: string, titles: INameCodeRs[] } }) => {
    this.jobTitles = jobTitles;
  }
}
export const globalContext = new Context();
